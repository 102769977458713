import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import {Alert,Container,Row,Col,Form,Button,Table,Card} from 'react-bootstrap'
import Logo from '../logo.png'
import Flag from 'react-world-flags'
import { CircleFlag } from 'react-circle-flags'
import loading from './loading.gif'

export default function PopularCountries() {
    const [wallOfFame,setWallOfFame]=useState([])
    const [data,setData]=useState([])
    const [isLoading,setIsLoading]=useState(true)

    useEffect(()=>{
        loadData()
    },[])

    const loadData=()=>{
        //setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'manager/dashboard/popularcountries/'+sessionStorage.getItem('SportID'),{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
              }            
          })
        .then(res=>{
            setIsLoading(false)
            setData(res.data)
        }).catch(err=>{
            setIsLoading(false)
    
        }) 
      }  

      const dataList=data.map(item=>{
        return(
            <tr>
                <td valign="top">

                    <CircleFlag countryCode={ item.CountryCode.toLowerCase()} height="35" />
                </td>
                <td valign="top">{item.Country}</td>
                <td></td>
                <td valign="top" style={{ textAlign: 'right' }}>{item.c}</td>
            </tr>
        )
      })

  return (
    <Card style={{ width: '100%' ,boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',border: '1px solid #f0f0f0', minHeight: '520px', filter: 'drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.21))'}}>
    <Card.Body>
      <Card.Title>Top Countries</Card.Title>
      <Card.Text>
        {!isLoading &&
        <Table width="100%" borderless>
            <thead>
                
                <tr style={{borderBottom:'1px solid #000',borderTop:'1px solid #000'}}>
                <td colSpan={2} style={{fontSize:'0.8125rem',letterSpacing:'1px'}}>COUNTRY</td>
                <td width="50"></td>
                <td style={{fontSize:'0.8125rem',letterSpacing:'1px', textAlign:"end"}}>PLAYERS</td>
                </tr>
                
            </thead>
            <tbody>
              <tr>
                <td colSpan="4" style={{ height: '20px' }}></td>
              </tr>
                {dataList}
            </tbody>
        </Table>
        }
        {isLoading &&
          <p align="center"><img src={loading} width="200"/></p>
        
        }
      </Card.Text>
    </Card.Body>
  </Card>
  )
}
