// videoTutorial.js
import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Button } from '@mui/material';


const VideoTutorial = () => {
  return (
    <Card 
      sx={{
        margin: '0 auto',
        boxShadow: 3,
        borderRadius: 2,
        overflow: 'hidden',
        padding:2,
        minHeight: '520px',
        
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', bgcolor: '#7a6cc824', padding: 2}}>
        <CardMedia
          component="video"
          src="/video.mp4" // Replace with your video path
          controls
          sx={{
            width: '300px',
            height: 200,
            objectFit: 'cover',
          }}
        />
      </Box>
      <CardContent>
        <Typography variant="h6" sx={{ textAlign: 'start', fontWeight: 'bold' }}>
          New Help Video
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'start', color: 'text.secondary', marginBottom: 4 }}>
          Learn how to use the platform.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <div style={{width: '50px',height: '50px', backgroundColor: '#7a6cc824', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto',borderRadius: '5px', display:"flex"}} >
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="mediumorchid"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon icon-tabler icons-tabler-outline icon-tabler-calendar-event"
                    >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                    <path d="M16 3l0 4" />
                    <path d="M8 3l0 4" />
                    <path d="M4 11l16 0" />
                    <path d="M8 15h2v2h-2z" />
                    </svg>
                </div>   
                    <div>
                      <Typography variant="body2" color="text.secondary" >
                      17 August 2024
                      </Typography>
                      <Typography variant="body2" color="text.secondary" fontSize={12}>
                      Date
                      </Typography>
                    </div> 
            </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <div style={{width: '50px',height: '50px', backgroundColor: '#7a6cc824', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto',borderRadius: '5px', display:"flex"}} >
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
                stroke="mediumorchid"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon icon-tabler icons-tabler-outline icon-tabler-clock-play"
                >
                <path d="M12 7v5l2 2" />
                <path d="M17 22l5 -3l-5 -3z" />
                <path d="M13.017 20.943a9 9 0 1 1 7.831 -7.292" />
                </svg>
            </div>
            <div>
              <Typography variant="body2" color="text.secondary">
                2 minutes
              </Typography>
              <Typography variant="body2" color="text.secondary" fontSize={12}>
                Duration
              </Typography>
            </div>
          </Box>
        </Box>
        <Button
          variant="contained"
          fullWidth
          sx={{
            bgcolor: '#7769ef',
            color: '#fff',
            textTransform: 'none',
            ':hover': { bgcolor: '#4500b5' },
            mt:3
          }}
        >
          Watch the video
        </Button>
      </CardContent>
    </Card>
  );
};

export default VideoTutorial;
