import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams ,Link} from 'react-router-dom'
import axios from 'axios'
import {Alert,Container,Row,Col,Form,Button,Table,Card} from 'react-bootstrap'
import Logo from '../logo.png'
import { CircleFlag } from 'react-circle-flags'
import loading from './loading.gif'

export default function FavouritePlayers() {
    const [wallOfFame,setWallOfFame]=useState([])
    const [data,setData]=useState([])
    const [isLoading,setIsLoading]=useState(true)

    useEffect(()=>{
        loadData()
    },[])

    const loadData=()=>{
        //setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'manager/dashboard/favouriteplayers/'+sessionStorage.getItem('SportID')+'/'+sessionStorage.getItem('userToken'),{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
              }            
          })
        .then(res=>{
            console.log(res.data)
            setData(res.data.slice(0, 5))
            setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
    
        }) 
      }  

      const dataList=data.map(item=>{
        return(
            <tr>
                <td>
                    <Link to={`/user/${item.UUID}`}>
                    <div style={{
                        width: '40px',  /* Adjust the size as needed */
                        height: '40px', /* Adjust the size as needed */
                        borderRadius: '50%',
                        borderColor:'gray',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundImage:`url(${process.env.REACT_APP_API_SERVER}files/${item.UUID}/123)`                  
                    }}/>
                    </Link>
                </td>
                <td valign="top">{item.Name}<br/><font color="gray">{item.Position}</font></td>
                <td></td>
                <td valign="top">{item.Country}</td>
                <td></td>
                <td valign="top">{item.v}</td>
            </tr>
        )
      })

  return (
    <Card style={{ width: '100%',boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',border: '1px solid #f0f0f0' }}>
    <Card.Body>
      <Card.Title>Favourite Players</Card.Title>
      <Card.Text>
      {!isLoading &&
        <Table width="100%" borderless>
            <thead>
                
                <tr style={{borderBottom:'1px solid #000',borderTop:'1px solid #000'}}>
                <td colSpan={2} style={{fontSize:'0.8125rem',letterSpacing:'1px'}}>PLAYER NAME & POSITION</td>
                <td width="50"></td>
                <td style={{fontSize:'0.8125rem',letterSpacing:'1px'}}>COUNTRY</td>
                <td width="50"></td>
                <td style={{fontSize:'0.8125rem',letterSpacing:'1px'}}>POINTS</td>
                </tr>
                
            </thead>
            <tbody>
                {dataList}
            </tbody>
        </Table>
        }

        {isLoading &&
          <p align="center"><img src={loading} width="200"/></p>
        
        }
      </Card.Text>
    </Card.Body>
  </Card>
  )
}
