import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Lightbox from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css'; 
import {Box} from '@mui/material'

export default function UserPosts(params) {
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const [gallerySlides, setGallerySlides] = useState([]);

    useEffect(() => {
        loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadData = () => {
        setIsLoading(true);
        axios
            .get(
                process.env.REACT_APP_API_SERVER +
                    'manager/dashboard/userposts/' +
                    params.uuid,
                {
                    headers: {
                        Authorization: 'Bearer ' + sessionStorage.getItem('mngapiToken'),
                    },
                }
            )
            .then((res) => {
                const postsData = res.data.results;

              
                const slides = postsData
                    .filter((item) => item.Photo.length > 0)
                    .map((item) => ({
                        src: process.env.REACT_APP_API_SERVER + 'postfiles/' + item.UUID + '/123',
                        title: item.Descr, 
                        description: item.Descr, 
                    }));

                setPosts(postsData);
                setGallerySlides(slides);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    const handleImageClick = (index) => {
        setLightboxIndex(index);
        setLightboxOpen(true);
    };

    const postsList = posts.map((item, index) => (
        <div key={item.UUID} style={{ margin: '10px', display: 'inline-block', position: 'relative' }}>
            {item.Photo.length > 0 && (
                <div style={{ textAlign: 'center' }}>
                    <img
                        src={process.env.REACT_APP_API_SERVER + 'postfiles/' + item.UUID + '/123'}
                        alt={item.Descr}
                        width="300"
                        height="300"
                        style={{
                            cursor: 'pointer',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                            objectFit: 'cover',
                        }}
                        onClick={() => handleImageClick(index)}
                    />
                    {item.Descr && ( // �� ����ǵ� �������Ʈ (caption), ��ĵ ��Ʊ����ı� Ŀ ����ù�
                        <div
                            style={{
                                position: 'absolute',
                                bottom: '0',
                                left: '0',
                                right: '0',
                                background: 'rgba(0, 0, 0, 0.5)', // ����� background �� opacity
                                color: '#fff',
                                padding: '5px',
                                textAlign: 'center',
                                fontSize: '14px',
                                borderBottomLeftRadius: '8px',
                                borderBottomRightRadius: '8px',
                            }}
                        >
                            {item.Descr}
                        </div>
                    )}
                </div>
            )}
        </div>
    ));

    return (
        <>
            <Box  display={'flex'} justifyContent={'center'}  marginLeft={'auto'} marginRight={'auto'} backgroundColor={"#8e8f9b05"} padding={6} marginTop={2} borderRadius={2}  position="relative"  boxShadow={'0 4px 8px rgba(0,0,0,0.3)'}>

                <div style={{position: 'absolute', top: 15, left: 10, padding: '3px 20px', color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '20px', fontSize:12}}>User Library</div>

                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop:40 }}>
                    {postsList}
                </div>
            </Box>

            {lightboxOpen && (
                <Lightbox
                    open={lightboxOpen}
                    close={() => setLightboxOpen(false)}
                    slides={gallerySlides}  // slides �� Ŀ title ��� description
                    index={lightboxIndex}
                    animation={{ fade: 500 }}
                    plugins={[Captions]} // ����������ͼ� Ŀ plugin Captions
                    captions={{
                        hidden: false, // ����� ı captions ���Ĭ
                        showToggle: true, // ��Ʊ����� Ŀ ��ż�� toggle ��� captions
                        descriptionTextAlign: "center", // �������� �Ÿų�����÷ Ŀ� ��������
                    }}
                />
            )}
        </>
    );
}
