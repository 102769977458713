import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,Link ,useNavigate} from 'react-router-dom'
import axios from 'axios'
import {Alert,Container,Form,Card,Navbar,NavDropdown,Nav,Table,Modal,Row,Col,Badge} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faVideo} from '@fortawesome/free-solid-svg-icons'
import { FaHome, FaLink, FaUsers,FaList,FaBasketballBall,FaMobile} from 'react-icons/fa';
import moment from 'moment';
import loading from './loading.gif'
import { Box} from '@mui/material';
import { Paper} from '@mui/material';
import { Button} from '@mui/material';

import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'




export default function DashboardGeneralStats() {
  const [wallOfFame,setWallOfFame]=useState([])
  const [data,setData]=useState([])
  const [activeSessions,setActiveSessions]=useState(0)
  const [activeSessionsEngaged,setActiveEngaged]=useState(0)
  const [activeSessionsChallenges,setActiveSessionsChallenges]=useState(0)
  const [dataTot,setDataTot]=useState(0)
  const [isLoading,setIsLoading]=useState(true)
  const navigate=useNavigate ()

  useEffect(()=>{
    loadData()
    loadDataChallnges()
    loadDataEngaged()
},[])

const loadData=()=>{
  const obj={
    SportID:sessionStorage.getItem('SportID'),
    Countries:JSON.parse(sessionStorage.getItem('appCountries'))
}

//console.log(obj)
    setIsLoading(true)
    axios.post(process.env.REACT_APP_API_SERVER+'manager/dashboard/dashboardgenstatsactivesessions',obj,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
          }            
      })
    .then(res=>{
      setActiveSessions(res.data.results[0].ActiveSessions)
        setIsLoading(false)
    }).catch(err=>{
        setIsLoading(false)

    }) 
}  

const loadDataChallnges=()=>{
  const obj={
    SportID:sessionStorage.getItem('SportID'),
    Countries:JSON.parse(sessionStorage.getItem('appCountries'))
}

//console.log(obj)
    setIsLoading(true)
    axios.post(process.env.REACT_APP_API_SERVER+'manager/dashboard/dashboardgenstatschellenges',obj,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
          }            
      })
    .then(res=>{
      setActiveSessionsChallenges(res.data.results[0].ActiveSessions)
        setIsLoading(false)
    }).catch(err=>{
        setIsLoading(false)

    }) 
}  


const loadDataEngaged=()=>{
  const obj={
    SportID:sessionStorage.getItem('SportID'),
    Countries:JSON.parse(sessionStorage.getItem('appCountries'))
}

//console.log(obj)
    setIsLoading(true)
    axios.post(process.env.REACT_APP_API_SERVER+'manager/dashboard/dashboardgenstatsengaged',obj,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
          }            
      })
    .then(res=>{
      setActiveEngaged(res.data.results[0].ActiveSessions)
        setIsLoading(false)
    }).catch(err=>{
        setIsLoading(false)

    }) 
}  

  return (
    <>


<Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2} marginTop={10}>
      <Box flex={1} >
      <Paper elevation={3} sx={{ padding: 2, height:'200px' }}>
          <Box display="flex" alignItems="start" >
              <Box flexGrow={1}>
                    <Typography variant="h6"> Welcome back <b>{sessionStorage.getItem('userName')}</b> </Typography>
                    <Typography variant="subtitle1">Basic user</Typography>
                    <Button onClick={()=>navigate('/profile')} variant="contained" color="primary" sx={{marginTop: '30px', fontSize:"12px", textTransform:"lowercase"}} >View Details</Button>
               </Box>         
              <Box  sx={{
                      backgroundImage: `url("${sessionStorage.getItem('usrPhoto')}")`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center', 
                      width: '200px',
                      height: '120px',
                      marginTop:'10px'
                    }}  >
              </Box>
          </Box>
      </Paper>
      </Box>
      
      <Box flex={2} >
        <Paper elevation={3} sx={{ padding: 2, minHeight: '200px' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">Statistics</Typography>
            <Typography variant="body2" color="textSecondary">
              Updated 1 hour ago
            </Typography>
          </Box>

          {/* Responsive Box */}
          
          <Box
    display="flex"
    justifyContent="space-around"
    sx={{
      flexDirection: {
        xs: 'column', // κάθετη διάταξη για mobile (xs)
        sm: 'row',    // οριζόντια διάταξη για desktop (md+)
      },
      alignItems: 'center', // Κέντρο για κάθετη διάταξη
      gap: 2, // Κενό ανάμεσα στα στοιχεία
    }}
  >
    {/* Box 1 */}
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          width: '60px',
          height: '60px',
          backgroundColor: 'rgba(133, 200, 108, 0.1)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          borderRadius: '5px',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="lightseagreen"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="icon icon-tabler icons-tabler-outline icon-tabler-clock-check"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M20.942 13.021a9 9 0 1 0 -9.407 7.967" />
          <path d="M12 7v5l3 3" />
          <path d="M15 19l2 2l4 -4" />
        </svg>
      </Box>
      <Box sx={{ marginLeft: '20px' }}>
        <Typography variant="h6" sx={{textAlign: 'left'}}><b>{activeSessions}</b></Typography>
        <Typography variant="body2" sx={{textAlign: 'left', fontSize:'13px'}}>
          <span style={{ display: 'block' }}>Active</span>
          <span style={{ display: 'block' }}>Sessions</span>
        </Typography>
      </Box>
    </Box>

    {/* Box 2 */}
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          width: '60px',
          height: '60px',
          backgroundColor: 'rgba(76, 100, 148, 0.1)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          borderRadius: '5px',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="cornflowerblue"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="icon icon-tabler icons-tabler-outline icon-tabler-users-group"
        >
          <path d="M10 13a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
          <path d="M8 21v-1a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v1" />
          <path d="M15 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
          <path d="M17 10h2a2 2 0 0 1 2 2v1" />
          <path d="M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
          <path d="M3 13v-1a2 2 0 0 1 2 -2h2" />
        </svg>
      </Box>
      <Box sx={{ marginLeft: '20px' }}>
      <Typography variant="h6" sx={{textAlign: 'left'}}><b>{activeSessionsEngaged}</b></Typography>
        <Typography variant="body2" sx={{textAlign: 'left', fontSize:'13px'}}>
          <span style={{ display: 'block' }}>Engaged</span>
          <span style={{ display: 'block' }}>Users</span>
        </Typography>
      </Box>
    </Box>

    {/* Box 3 */}
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              width: '60px',
              height: '60px',
              backgroundColor: 'rgba(255, 0, 0, 0.1)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
              borderRadius: '5px',
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
              stroke="lightcoral"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="icon icon-tabler icons-tabler-outline icon-tabler-brand-dribbble"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
              <path d="M9 3.6c5 6 7 10.5 7.5 16.2" />
              <path d="M6.4 19c3.5 -3.5 6 -6.5 14.5 -6.4" />
              <path d="M3.1 10.75c5 0 9.814 -.38 15.314 -5" />
            </svg>
          </Box>
          <Box sx={{ marginLeft: '20px' }}>
          <Typography variant="h6" sx={{textAlign: 'left'}}><b>{activeSessionsChallenges}</b></Typography>
            <Typography variant="body2" sx={{textAlign: 'left', fontSize:'13px'}}>
              <span style={{ display: 'block' }}>Challenge</span>
              <span style={{ display: 'block' }}>Participants</span>
            </Typography>
          </Box>
        </Box>
      </Box>

        </Paper>
      </Box>

    </Box>

    {/* <Card style={{ width: '100%' ,boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',border: '1px solid #f0f0f0'}}>
    <Card.Body>
      <Card.Title>Welcome {sessionStorage.getItem('userName')}<hr/></Card.Title>
      <Card.Text>
        {!isLoading &&
            <>
                <Col md={4}>
                  <Card>
                      <Card.Body>
                          <Card.Title><FaUsers/> Active Sessions: {activeSessions}</Card.Title>
                          <Card.Text></Card.Text>
                      </Card.Body>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                      <Card.Body>
                          <Card.Title><FaMobile/> Engaged Users: {activeSessionsEngaged}</Card.Title>
                          <Card.Text></Card.Text>
                      </Card.Body>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                      <Card.Body>
                          <Card.Title><FaBasketballBall/> Challenge Partcipants: {activeSessionsChallenges}</Card.Title>
                          <Card.Text></Card.Text>
                      </Card.Body>
                  </Card>
                </Col>
            </>



        }
        {isLoading &&
          <p align="center"><img src={loading} width="200"/></p>
        
        }
      </Card.Text>
    </Card.Body>
  </Card> */}
</>
  )
}
