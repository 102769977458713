import React,{useContext, useEffect,useState,useRef} from 'react'
import axios from 'axios'
import {Link,NavLink,useNavigate,useParams } from 'react-router-dom'
import {UserContext} from '../context/UserContext'
import {Alert,Container,Form,Button,Card,Navbar,NavDropdown,Nav,Table,Modal} from 'react-bootstrap';
import logo from '../logo.png'
import { MultiSelect } from "react-multi-select-component";
import moment from 'moment';
import DataTable from 'react-data-table-component';
import exportFromJSON from 'export-from-json' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faShop,faPlus, faShoppingBasket,faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import loading from './loading.gif'

export default function PlayersList() {
    const [lists,setLists]=useState([])
    const [countries,setCountries]=useState([])//useState(JSON.parse(sessionStorage.getItem('appCountries')))
    const [appPositions,setAppPositions]=useState([])
    const [positions,setPositions]=useState([])
    const [queryRsults,setQueryResults]=useState([])
    const [isLoading,setIsLoading]=useState(false)
    const [data,setData]=useState([])
    const [a1,setA1]=useState(0)
    const [a2,setA2]=useState(0)
    const [h1,setH1]=useState(0)
    const [h2,setH2]=useState(0)
    const [w1,setW1]=useState(0)
    const [w2,setW2]=useState(0)
    const [o,setO]=useState(0)
    const [s,setS]=useState('')
    const [listName,setListName]=useState('')
    const [sqlCriteria,setSqlCriteria]=useState('')
    const [show,setShow]=useState(false)
    const resultsTbl=useRef()
    const navigate=useNavigate()

    useEffect(()=>{
        if (listName.length>0){
            createSQL()
        }else{
            setQueryResults([])
        }
        
    },[listName])

    const columns = [
        {
            name: '#',
            selector: (row,index) =>  index+1,
            sortable: true,
            maxWidth:'5px'
        },
        {
            name: '', 
            selector: row => (
                <img 
                src={`${process.env.REACT_APP_API_SERVER}files/${row.UUID}/123`} 
                    alt="User icon" 
                    style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                        objectFit: 'cover'
                    }} 
                />
            ),
            sortable: true,
            maxWidth:'5px'
        },
        
        {
            name: 'Name',
            selector: (row,index) =>  row.FirstName+ ' '+row.LastName,
            sortable: true
            
        },
        {
            name: 'Age',
            selector: (row,index) =>  row.Age,
            sortable: true,
            maxWidth:'25px'
        }, 
        {
            name: 'Country',
            selector: (row,index) =>  row.Country,
            sortable: true,
            maxWidth:'50px'
        },     
        {
            name: 'Position',
            selector: (row,index) =>  row.Position,
            sortable: true,
            maxWidth:'100px'
        }, 
        {
            name: 'Orientation',
            selector: (row,index) =>  row.LeftRight==1 ? 'Right' : row.LeftRight==2 ?  'Left' : 'Both Left & Right' ,
            sortable: true,
            maxWidth:'50px'
        },   
        {
            name: 'Height',
            selector: (row,index) =>  row.Height,
            sortable: true,
            maxWidth:'25px'
        },  
        {
            name: 'Weight',
            selector: (row,index) =>  row.Weight,
            sortable: true,
            maxWidth:'25px'
        }, 
        {
            name: 'Team',
            selector: (row,index) =>  row.Team,
            sortable: true
        } ,
        {
            name: 'Points',
            selector: (row,index) =>  row.Points,
            sortable: true,
            maxWidth:'25px',
            style: {
                textAlign: 'right'
            }
        }, 
        {
            name: 'Posts',
            selector: (row,index) =>  row.Posts,
            sortable: true,
            maxWidth:'25px'
        }, 
        {
            name: 'Challenges',
            selector: (row,index) =>  row.Challenges,
            sortable: true,
            maxWidth:'25px'
        },  
        {
            name: '',
            selector: row => <Button variant="secondary" onClick={()=>navigate('/user/'+row.UUID)}><FontAwesomeIcon icon={faEdit}/></Button>,
            sortable: true,
            maxWidth:'5px'
        },           
        
    ]


    useEffect(()=>{
        loadLists()
      },[])

    const loadLists=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'manager/listscriteria/lists/'+sessionStorage.getItem('userToken'),{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
                }            
            })
          .then(res=>{
            if (res.data.err===0){
              //console.log(res.data.results)
              setLists(res.data.results)
            }else{
              alert('An error has occured')
            }
            
            setIsLoading(false)
            
          }).catch(err=>{
              setIsLoading(false)
              console.log(err)
          }) 
      }

      const loadList= (listID)=>{
        if (listID==='0'){
            setQueryResults([])
            setListName('')
        }else{
            setIsLoading(true)
            axios.get(process.env.REACT_APP_API_SERVER+'manager/listscriteria/list/'+listID,{
                    headers: {
                        'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
                        }            
                    })
                .then(res=>{
                    //console.log(res.data)
                    if (res.data.err===0){
    
                    setCountries(res.data.results.countries)
                    setPositions(res.data.results.positions)
                        setListName(res.data.results.listName)
                        setA1(res.data.results.a1)
                        setA2(res.data.results.a2)
                        setH1(res.data.results.h1)
                        setH2(res.data.results.h2)
                        setW1(res.data.results.w1)
                        setW2(res.data.results.w2)
                        setO(res.data.results.o)
                        setS(res.data.results.s)
    
                    }else{
                    alert('An error has occured')
                    }
                    
                    
                    // setTimeout(()=>{
                    //     createSQL()
                    // },1000)
                    
                }).catch(err=>{
                    setIsLoading(false)
                    console.log(err)
                }) 
        }



      }


      const listsList=lists.map(item=>{
        return(
          <option value={item.UUID} >{item.listName}</option>
        )
      })


      const createSQL=()=>{


        var sqlCountries=''
        var sql=''
        const SportID=sessionStorage.getItem('SportID')

        countries.forEach((item,index)=>{
            if (index===0){
                sqlCountries=sqlCountries+ ' CountryIDINT='+item.value
            }else{  
                sqlCountries=sqlCountries+ ' OR CountryIDINT='+item.value
            }
        })
        if (sqlCountries.length>0){
            sqlCountries='('+sqlCountries+')'
        }
        sql=sqlCountries

        var sqlPositions=''
        positions.forEach((item,index)=>{
            if (index===0){
                sqlPositions=sqlPositions+ ' Users.PositionID='+item.value
            }else{  
                sqlPositions=sqlPositions+ ' OR Users.PositionID='+item.value
            }
        })
        if (sqlPositions.length>0){
            sqlPositions='('+sqlPositions+')'
        }
        if (sql.length>0 && sqlPositions.length>0){
            sql=sql+' AND '+sqlPositions
        }else{
            sql=sql+sqlPositions
        }
        
        var sqlHeight1=''
        if (h1>0){
            sqlHeight1=' Height>='+h1*100
        }
        if (sql.length>0 && sqlHeight1.length>0){
            sql=sql+' AND '+sqlHeight1
        }else{
            sql=sql+sqlHeight1
        }        

        var sqlHeight2=''
        if (h2>0){
            sqlHeight2=' Height<='+h2*100
        }
        if (sql.length>0 && sqlHeight2.length>0){
            sql=sql+' AND '+sqlHeight2
        }else{
            sql=sql+sqlHeight2
        }  


        var sqlWeight1=''
        if (w1>0){
            sqlWeight1=' Weight>='+w1
        }
        if (sql.length>0 && sqlWeight1.length>0){
            sql=sql+' AND '+sqlWeight1
        }else{
            sql=sql+sqlWeight1
        }        

        var sqlWeight2=''
        if (w2>0){
            sqlWeight2=' Weight<='+w2
        }
        if (sql.length>0 && sqlWeight2.length>0){
            sql=sql+' AND '+sqlWeight2
        }else{
            sql=sql+sqlWeight2
        }  


        var sqlOrientation=''
        if (o>0){
            sqlOrientation=' LeftRight='+o
        }
        if (sql.length>0 && sqlOrientation.length>0){
            sql=sql+' AND '+sqlOrientation
        }else{
            sql=sql+sqlOrientation
        }  

        console.log(sql)

        setSqlCriteria(sql)

        axios.post(process.env.REACT_APP_API_SERVER+'manager/listscriteria/results',{sql,SportID},{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
                }            
            })
          .then(res=>{
            let listResults=res.data.results
            let results=res.data.results
            const todayDate = moment().year()
            
            results.forEach(item=>{
                const dateOfBirth=moment(item.DateOfBirth).year()
                item.Age=todayDate-dateOfBirth
                //console.log(todayDate-dateOfBirth)
            })
            
            if (a1>0){
                listResults=listResults.filter(item=>item.Age>=a1)
                //results=[...listResults]
            }
            if (a2>0){
                listResults=listResults.filter(item=>item.Age<=a2)
                //results=[...listResults]
            }
            if (s.length>0){
                listResults=listResults.filter(item=>item.SearchStr.includes(s.toUpperCase()))
                //results=[...listResults]
            }
            //console.log(listResults)
            

            if (listResults.length>0){
                //alert('Query returned '+listResults.length+' results. Scroll down to view results.')
                setQueryResults(listResults)
                //resultsTbl.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }else{
                setQueryResults([])
                //alert('Query returned no results')
            }
            setIsLoading(false)
            //console.log(listResults)

          }).catch(err=>{

          }) 
    }

  return (
    <Container fluid>
        <br/>
    <Card>

      <Card.Body>
        <Card.Title>
            <table width="100%">
                <tr>
                    <td width="20"></td>
                    <td>
                        Players' Lists
                    </td>
                    <td align="right">
                        <Button onClick={()=>navigate('/lists')} variant="secondary">View/Edit Lists</Button>
                        &nbsp;&nbsp;
                        <Button onClick={()=>navigate('/list')} variant="success" style={{backgroundColor:'#7367f0',fontFamily:'tahoma'}}>+ Create New List</Button>
                    </td>
                    <td width="20"></td>
                </tr>

                <tr>
                <td colspan="4"><hr/></td>
                </tr>
                <tr>
                <td colspan="4">
                <Form.Select aria-label="Default select example" style={{width:'300px'}} onChange={(e)=>loadList(e.target.value)}>
                <option value="0">[SELECT LIST]</option>
                {listsList}
                </Form.Select>

                </td>
                </tr>
            </table>

        </Card.Title>


      </Card.Body>
    </Card>
    {!isLoading &&
    <DataTable
                columns={columns}
                data={queryRsults}
                highlightOnHover
                
                //expandableRows
                //expandableRowsComponent={ExpandedComponent}
                //pagination
        />  
    }

    {isLoading &&
          <p align="center"><img src={loading} width="200"/></p>
        
        }
    </Container>
  )
}
