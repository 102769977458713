import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import {Alert,Container,Form,Button,Card,Navbar,NavDropdown,Nav,Table,Modal,Row,Col,Badge} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faVideo } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import VideoPlayer from 'react-video-js-player';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList,ResponsiveContainer,PieChart,Pie,Cell} from 'recharts';

export default function UserStats(params) {
    const [stats,setStats]=useState([])
    const [isLoading,setIsLoading]=useState(false)

    const data = [
        { name: 'Group A', value: 400 },
        { name: 'Group B', value: 300 },
      ];
      
      const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042','#c40ced','#0088FE', '#00C49F', '#FFBB28', '#FF8042','#c40ced'];

    useEffect(()=>{
        loadData()
    },[])

    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'manager/dashboard/userstats/'+params.uuid,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
              }            
          })
        .then(res=>{
    
    
            //console.log(res.data)
            res.data.results.forEach(item=>{
                item.BarLabel=item.StatName+': '+item.Value+'%'
            })
            setStats(res.data.results)
    
           setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
    
        })          
    }

    const statsList=stats.map((item,index)=>{
        const data = [
            { name: item.StatName, value:item.Value },
            { name: '', value: 100-item.Value },
          ];
          

        return(
            // <tr>
            //     <td><b>{item.StatName}</b></td>
            //     <td width="20"></td>
            //     <td>{item.Value}%</td>
            // </tr>


                <PieChart PieChart width={180} height={180}>
                    <Pie
                    data={data}

                    innerRadius={20}
                    outerRadius={40}
                    fill="#f0f0f0"
                    dataKey="value"
                    label
                    >
                 
                    <Cell key={`cell-1`} fill={COLORS[index]} />
                    <Cell key={`cell-2`} fill="#f0f0f0" />
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>  



        )
    })

  return (
    <Container fluid>

        <Card style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',border: '1px solid #f0f0f0'}}>

        <Card.Body>
            <Card.Title>
            <div style={{fontFamily:'Arial, Helvetica, sans-serif',color:'#6f6b7d',fontSize:'16px',fontWeight:'bold'}}>
            User Stats
            </div>

            </Card.Title>
            <Card.Text>
                {/* <table>
                    {statsList}
                </table> */}
            <div className="userStatsPie">
                    

                    {statsList}

                

            </div>
            </Card.Text>

        </Card.Body>
        </Card>

    </Container>
  )
}
